








































import { Component, Prop, Vue } from 'vue-property-decorator';
import { Post } from '@/typings';

@Component({})
export default class SinglePost extends Vue {
  @Prop({ type: Object, required: true }) post!: Post;
  isImageLoaded: boolean = false;

  mobileBreakPoint: MediaQueryList = window.matchMedia('(max-width: 600px)');
  isScreenMobile: boolean = this.mobileBreakPoint.matches;

  mounted(): void {
    this.mobileBreakPoint.onchange = this.mediaQueryHandler;
  }

  get fallbackImageSource(): string | null {
    const desktopPath: string | null = this.post.image_f ?? null;
    const mobilePath: string | null = this.post.image_mobile_f ?? null;
    return this.isScreenMobile && mobilePath ? mobilePath : desktopPath;
  }

  get isPhotoIncluded(): boolean {
    return !!this.post.image;
  }

  get mainImageSource(): string | null {
    const desktopPath: string | null = this.post.image ?? null;
    const mobilePath: string | null = this.post.image_mobile ?? null;
    return this.isScreenMobile && mobilePath ? mobilePath : desktopPath;
  }

  mediaQueryHandler(): void {
    this.isScreenMobile = this.mobileBreakPoint.matches;
  }

  onImageLoad(): void {
    this.isImageLoaded = true;
  }
}
