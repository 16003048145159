









import { Component, Vue } from 'vue-property-decorator';
import SinglePost from '@/components/news/SinglePost.vue';
import { posts } from './newsContent';
import { Post } from '@/typings';

@Component({ components: { SinglePost } })
export default class News extends Vue {
  posts: Post[] = posts;

  get latestPosts(): Post[] {
    return this.posts.filter((_post, key) => key < 10);
  }
}
